export const endpoints = {
    auth: {
        login: 'Sellers/login?include=user'
    },
    overview: {
        all: 'ReturnTrucks',
        byId: 'ReturnTrucks/{truckId}',
    },
    images: {
        upload: 'Images/return-trucks/{returnTruckId}/uploads',
        byId: 'Images/{imageId}'
    },
    recipients: {
        all: 'Recipients'
    },
    placeholder: '---- FOR STARTER PURPOSES ONLY ----'
}